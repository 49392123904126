import { Box, SimpleGrid, Text } from '@chakra-ui/react'
import Image from 'next/legacy/image'
import NextLink from 'next/link'

type ProductListProps = {
  products: Product[]
}

export type Product = {
  id: string
  title: string
  onlineStoreUrl: string
  featuredImage: {
    url: string
    height: number
    width: number
    id: string
  }
}

export const ProductList = (props: ProductListProps) => {
  return (
    <SimpleGrid
      columns={[2, 2, 3, 4]}
      gap='2px'
      backgroundColor='gray.150'
      padding='2px'>
      {props.products.map(product => (
        <Box
          key={product.id}
          overflow='hidden'
          pos='relative'
          role='group'
          backgroundColor='white'>
          <NextLink href={product.onlineStoreUrl} passHref legacyBehavior>
            <a>
              <Image
                src={product.featuredImage.url}
                width={product.featuredImage.width}
                height={product.featuredImage.height}
                alt={product.title}
              />
              <Text
                color='black'
                position='absolute'
                fontWeight='bold'
                zIndex={2}
                bottom={-20}
                left={0}
                right={0}
                padding={4}
                backgroundColor='white'
                boxShadow='0 0 4px rgba(0,0,0,0.1), 0 0 30px rgba(0,0,0,0.125)'
                opacity={0}
                _groupHover={{ bottom: 0, opacity: 1 }}
                sx={{
                  transition: 'opacity .5s ease-out, bottom .175s ease-out',
                }}>
                {product.title} ›
              </Text>
            </a>
          </NextLink>
        </Box>
      ))}
    </SimpleGrid>
  )
}
