// posts will be populated at build time by getStaticProps()

import { CardImage } from '@/components/CardImage'
import { Box, Heading, SimpleGrid } from '@chakra-ui/react'
import { gql } from 'graphql-request'
import NextLink from 'next/link'

type Props = {
  builds: any
}

export const BuildListData = () => {
  return gql`
    query Entries($limit: Int) {
      entries(collection: "builds", limit: $limit, sort: "date desc") {
        data {
          ... on Entry_Builds_Build {
            id
            title
            slug
            image {
              ... on Asset_Main {
                id
                url
                width
                height
              }
            }
          }
        }
      }
    }
  `
}

export const BuildList = (props: Props) => {
  return (
    <SimpleGrid p={[4, 8]} columns={[1, null, 3]} spacing='40px'>
      {props.builds.entries.data.map((data: any) => (
        <Box
          key={data.id}
          backgroundColor='gray.100'
          color='blue.800'
          _hover={{ color: 'red.400' }}>
          <NextLink href={`/builds/${data.slug}`}>
            <CardImage
              alt={data.title}
              src={
                data.image?.url ||
                'http://debossgarage.s3.amazonaws.com/general/image-placeholder-not-found.png'
              }
            />
            <Heading p={2} pb={1} pl={3}>
              {data.title}
            </Heading>
          </NextLink>
        </Box>
      ))}
    </SimpleGrid>
  )
}
