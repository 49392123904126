import { Box, HStack, Link, Text } from '@chakra-ui/react'
import { gql } from 'graphql-request'
import Image from 'next/legacy/image'
import { useEffect, useRef } from 'react'

export const partnerListData = () => {
  return gql`
    query Entries {
      entries(collection: "partners", sort: "order") {
        data {
          ... on Entry_Partners_Partners {
            id
            website
            logo {
              id
              url
              width
              height
            }
          }
        }
      }
    }
  `
}

export const PartnersAnimated = (props: any) => {
  const partnersRef: any = useRef()

  useEffect(() => {
    const partnersDiv =
      partnersRef.current &&
      partnersRef.current.animate(
        [{ transform: `translateX(-${props.partnersDivWidth}px)` }],
        {
          fill: 'forwards',
          duration: props.partnersDivWidth * 60,
          iterations: Number.POSITIVE_INFINITY,
        }
      )

    const scrollPartners = (e: any) => {
      if (e[0].isIntersecting == true) {
        partnersDiv.play()
      }
      if (e[0].isIntersecting == false) {
        partnersDiv.pause()
      }
    }

    const partnerObserver = new IntersectionObserver(scrollPartners)

    partnerObserver.observe(partnersRef.current)
    partnersDiv.pause()

    partnersRef.current.addEventListener('mouseenter', () => {
      partnersDiv.pause()
    })
    partnersRef.current.addEventListener('mouseleave', () => {
      partnersDiv.play()
    })
  }),
    []

  return (
    <Box
      w='full'
      overflow='hidden'
      mt={8}
      p={4}
      sx={{
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': { display: 'none' },
      }}>
      <Text textAlign='center' mb={4} textTransform='uppercase'>
        <Link href='/partners'>Our Amazing Partners</Link>
      </Text>
      <HStack
        ref={partnersRef}
        spacing={10}
        mx='-10px'
        pl='25vw'
        display='flex'
        width={props.partnersDivWidth}>
        {props.partners.entries.data.map((data: any) =>
          data.logo ? (
            <Box
              key={data.id}
              className={`js-partners-item item-${data.id}`}
              p={2}
              h={12}
              m='0'
              py='0'>
              <Link
                position='relative'
                w={`${data.logo.width / (data.logo.height / 40)}px`}
                h={10}
                display='block'
                href={data.website}
                sx={{
                  filter: 'grayscale(100%)',
                  _hover: { filter: 'grayscale(0%)' },
                }}
                overflow='hidden'>
                <Image
                  src={data.logo.url}
                  height='40'
                  width={`${data.logo.width / (data.logo.height / 40)}`}
                  objectFit='contain'
                  objectPosition='center center'
                  alt={data.slug}
                />
              </Link>
            </Box>
          ) : null
        )}
      </HStack>
    </Box>
  )
}
