import { Box, Button, Container, Heading, Stack } from '@chakra-ui/react'
import Image from 'next/legacy/image'
import { Product, ProductList } from './productList'

type StoreCalloutProps = {
  products: Product[]
}

export const StoreCallout = (props: StoreCalloutProps) => {
  return (
    <Box
      backgroundColor='gray.150'
      py={24}
      textAlign='center'
      position='relative'>
      <Box position='absolute' top={0} left={0} right={0} bottom={0} zIndex={1}>
        <Image
          src='/img/store-background.webp'
          alt=''
          layout='fill'
          objectFit='cover'
        />
      </Box>
      <Container maxWidth='7xl'>
        <Stack align='center' spacing={4} position='relative' zIndex={2}>
          <Heading
            color='red.400'
            fontSize={{ base: '6xl', md: '8xl' }}
            left={0}
            margin={0}
            textAlign='center'
            width='full'
            zIndex={0}>
            Hot New Merch
          </Heading>
          <ProductList products={props.products} />
          <Stack direction='row' align='center' spacing={8} pt={6}>
            <Heading margin='5px 0 0' color='black'>
              Get it on ›
            </Heading>
            <Button
              as='a'
              href='https://merch.debossgarage.com'
              colorScheme='red'
              size='lg'>
              Shop the store
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}
