import { numberWithCommas } from '@/lib/numberWithCommas'
import { Box, Button, Heading, Stack } from '@chakra-ui/react'
import NextImage from 'next/legacy/image'
export const YoutubeCallout = (props: any) => {
  return (
    <Box position='relative' py={20} px={8} overflow='hidden' height='100%'>
      <Box pos='absolute' inset={0} zIndex={1}>
        <NextImage
          src='/img/deboss-youtube-callout-background.webp'
          layout='fill'
          alt=''
          objectPosition='center'
          objectFit='cover'
        />
      </Box>
      <Stack gap={8}>
        <Box
          zIndex={2}
          display='flex'
          justifyContent='center'
          flexDirection='column'>
          <Box pos='relative' maxWidth='440px' margin='0 auto'>
            <NextImage
              src='/img/deboss-youtube-callout-logo.webp'
              alt='Deboss Garage on YouTube'
              width='880'
              height='360'
            />
          </Box>
          {props.stats && (
            <Heading
              mt={4}
              fontSize='xl'
              color='white'
              textAlign='center'
              width='full'>
              New Videos Every Week / {props.stats?.videoCount} videos /{' '}
              {numberWithCommas(props.stats?.subscriberCount)} Subscribers
            </Heading>
          )}
        </Box>
        <Box
          pos='relative'
          display='flex'
          alignItems='center'
          justifyContent='center'
          zIndex={3}>
          <Button
            href='https://www.youtube.com/debossgarage'
            size='lg'
            as='a'
            target='_blank'>
            Watch Deboss Garage
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}
