import { errorImage } from '@/globals/globals'
import { chakra } from '@chakra-ui/react'
import NextImage from 'next/legacy/image'

const CoverImg = chakra(NextImage, {
  shouldForwardProp: prop =>
    [
      'width',
      'height',
      'src',
      'alt',
      'quality',
      'placeholder',
      'blurDataURL',
      'loader ',
      'layout',
    ].includes(prop),
})

export const CardImage = (props: any) => {
  const { src, alt, height = 384, width = 600 } = props
  return (
    <CoverImg
      _groupHover={{
        transform: 'scale(1.025)',
      }}
      alt={alt ?? ''}
      backgroundColor='gray.300'
      height={height}
      objectFit='cover'
      quality={50}
      src={src ?? errorImage}
      transition='all 0.2s'
      layout='responsive'
      width={width}
    />
  )
}
